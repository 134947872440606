.list-unstyled li {
    font-size: 14px;
    font-family: sans-serif;
    text-align: left;
}
card-container .card-img img {
    width: 100%;
    height: auto;
}

.card-container .card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-container .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space is distributed */
}