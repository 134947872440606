body {
    font-family: 'Roboto', sans-serif;
}
/* Custom styles for navbar */
#login-dp {
    min-width: 250px;
    padding: 14px 14px 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .8);
}

#login-dp .help-block {
    font-size: 12px;
}

#login-dp .bottom {
    background-color: rgba(255, 255, 255, .8);
    border-top: 1px solid #ddd;
    clear: both;
    padding: 14px;
}

#login-dp .social-buttons {
    margin: 12px 0;
}

#login-dp .social-buttons a {
    width: 49%;
}

#login-dp .form-group {
    margin-bottom: 10px;
}

.btn-fb {
    color: #fff;
    background-color: #3b5998;
}

.btn-fb:hover {
    color: #fff;
    background-color: #496ebc;
}

.btn-google {
    color: #1f1f1f;
    background-color: #f2f2f2;
}

.btn-google:hover {
    color: #1f1f1f;
    background-color: #f2f2f2;
}

a#navbarDropdown {
    color: #fff;
}

@media (max-width: 768px) {
    #login-dp {
        background-color: inherit;
        color: #fff;
    }

    #login-dp .bottom {
        background-color: inherit;
        border-top: 0 none;
    }
}