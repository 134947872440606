/* src/pages/ModularCourseEditor.css */

.course-editor-wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.sidebar {
    width: 300px;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    transform: translateX(-300px);
    transition: transform 0.3s ease-in-out;
}

.sidebar.open {
    transform: translateX(0);
}

.toggle-sidebar-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.editor-content {
    flex-grow: 1;
    padding: 20px;
}

.module-container {
    margin-top: 20px;
}

.module-add-buttons {
    margin-top: 20px;
}
/* src/pages/ModularCourseEditor.css */

/* Quiz Module */
.quiz-module {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

/* Chat Module */
.chat-module {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.chat-window {
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
}

.chat-message {
    padding: 5px 0;
}

.chat-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Image Module */
.image-module {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.image-placeholder img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}
#logout .fa-portal-exit {
    color: #ffffff;
    cursor: pointer;
    margin-top: 6px;
}
/* Comments Module */
.comments-module {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.comments-list {
    margin-bottom: 20px;
}

.comment {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.comment-input {
    display: flex;
    gap: 10px;
}

.comment-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.comment-input button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.comment-input button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .course-editor-wrapper {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        transform: translateY(-100%);
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .sidebar.open {
        transform: translateY(0);
    }

    .toggle-sidebar-btn {
        top: auto;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}
