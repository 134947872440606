#portraitPreview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 2px dashed #adb5bd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

#spinner {
    position: absolute;
    z-index: 10;
}

#portrait {
    display: none;
}

.form-row {
    margin-top: 10px;
}

.form-row .col {
    padding-right: 5px;
    padding-left: 5px;
}

.startPageOptions div {
    min-height: 120px;
    cursor: pointer;
}

.startPageOptions label {
    height: 100%;
}

.startPageOptions span.interestTitle {
    font-weight: bold;
    color: #eeeeee;
    display: block;
}

.startPageOptions span.interestText {
    margin-top: .25rem;
    color: #dddddd;
    font-size: .875rem;
    display: block;
}

.checkmark {
    display: none;
}

.optionCheck:checked .checkmark {
    display: inline-block;
    margin-right: 0.5rem; /* Adjust the margin as needed */
    color: #007bff; /* Adjust color as needed */
}

canvas {
    display: none; /* Hide the canvas */
}

