/* src/pages/Main.styles */

body, html {
    height: 100%;
    margin: 0;
    scroll-behavior: smooth;
}
.section {
    min-height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    border-bottom: 10px solid rgb(100,100,100);
}
.section h1, .section h2, .section p {
    text-align: center;
}
.section-1 {
    background-image: url('/public/img/front-wellness.webp');
}
.section-2 {
    background-image: url('/public/img/front-videoconf.webp');
}
.section-3 {
    background-image: url('/public/img/front-community.webp');
}
.section-4 {
    background-image: url('/public/img/front-joinus.webp');
}
.section .container {
    background-color: rgba(0,0,0,0.2);
    border-radius:5px;
}
.section-4 .container {
    background-color: transparent;
}
