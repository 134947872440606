.notfound-container {
    text-align: center;
    margin-top: 50px;
    color: #333;
}

.notfound-title {
    font-size: 6rem;
    font-weight: bold;
    color: #ff6b6b;
}

.notfound-message {
    font-size: 1.5rem;
    margin: 20px 0;
}

.notfound-gif {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
}

.notfound-hint {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #777;
}

.notfound-link {
    text-decoration: none;
}

.notfound-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff6b6b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.notfound-button:hover {
    background-color: #ff5252;
}
