/* CSS HEX
--oxford-blue: #03112e;
--prussian-blue: #04395e;
--egg-blue: #4ecdc4;
--white: #ffffff;
--light-red: #ff6b6b;
*/
body {
    background-color: #eaeaea;
    color: #111111;

    font-family: 'Roboto', sans-serif;
}

.navbar-login {
    justify-content: space-between;
}

.navbar-custom {
    background-color: #16656c;
}

.navbar-custom .navbar-brand-custom {
    color: #eeeeee;
}

.login-box {
    margin-left: auto;
}

.card-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 100px; /* Ribbon width */
    height: 100px; /* Ribbon height */
    text-align: right;
}

.card-ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    width: 140px; /* Adjust the width to fit the text */
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 20px;
    right: -40px;
}

.card-ribbon.free span {
    background: linear-gradient(#5cb85c 0%, #419641 100%);
}

.card-ribbon.sale span {
    background: linear-gradient(#dc274b 0%, #881010 100%);
}

.line-container {
    display: flex;
    align-items: center;
    opacity: 0.15;
    padding: 10px 0;
}

.line {
    flex-grow: 1; /* Ensures that the lines take up equal space on either side */
    height: 2px; /* Height of the line */
    background-color: black; /* Color of the line */
}

.ball {
    width: 12px; /* Size of the ball */
    height: 12px; /* Size of the ball */
    border-radius: 50%; /* Makes the ball round */
    background-color: transparent; /* Color of the ball */
    border: 2px solid black;
    display: inline-block;
    margin: 0 10px; /* Spacing on either side of the ball */
}

#courseContainsBlock {
    font-size: 2rem;
}

#courseContainsBlock i {
    text-align: center;
    width: 1.25em;
}

.btn-larger {
    padding: 12px 24px; /* Increase padding for larger button size */
    font-size: 18px; /* Increase font size for better visibility */
    border-radius: 8px; /* Rounded corners like the second image */
    display: inline-block; /* To keep button formatting correct */
    margin: 10px 5px; /* Optional: Add some margin around the buttons */
    transition: background-color 0.3s, color 0.3s, border 0.3s; /* Smooth transition for hover effects */
}

.toc-item {
    cursor: pointer;
}

.navbar-custom {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ql-omega:after {
    content: "ðŸ–µ";
}

.clicked-toc-item {
    background-image: linear-gradient(to right, lightgreen, white);
}

.fullMode {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: white; /* Or any other color you prefer */
    overflow: auto; /* Allows scrolling if the content is longer than the screen */
}

/* Optional: hide the original editor container when in full mode */
#defaultMode.d-none {
    display: none;
}

.navbar-brand-custom {
    color: #ec5252;
    font-weight: bold;
}

.nav-link-custom {
    color: #eeeeee;
    margin-right: 15px;
    max-height: 32px;
}

.nav-link-custom:hover {
    color: #4ecdc4;
}

.search-bar {
    width: 40%; /* Adjust the width as needed */
}

.search-box {
    position: relative;
    margin: 0 auto; /* Center the search box */
    width: 40%; /* Adjust the width as desired */
}

.search-box input[type="search"] {
    width: 100%;
    font-size: 16px; /* Adjust font size as needed */
    border: 2px solid #ccc; /* Border color similar to the example */
    border-radius: 25px; /* Rounded borders */
    outline: none; /* Remove outline on focus */
}

.search-box input[type="search"]::placeholder {
    color: #aaa; /* Placeholder text color */
}

.navbar-custom .fa-user, .navbar-custom .fa-shopping-cart, .navbar-custom .fa-heart {
    margin: 0 10px;
}

.navbar-custom .badge {
    position: relative;
    top: -15px;
    right: -10px;
    background-color: #ec5252;
    padding:4px;
}

.navbar-toggler {
    border: none;
}

.navbar-custom + .navbar {
    background-color: rgb(4, 57, 94) !important; /* Match the background to your design */
}

.navbar-custom + .navbar .nav-link {
    color: rgb(238, 238, 238); /* Match the text color to your design */
    margin-right: 15px; /* Adjust the spacing between menu items */
}

.navbar-custom + .navbar .nav-link:hover {
    text-decoration: none;
    color: #aaa; /* Hover color */
}
.search-box label {
    width: 100%;
}

/* Customize this based on how you want the active page link to stand out */
.navbar-custom + .navbar .nav-link.active {
    color: #eee; /* Active link color */
}

#navbarSecondary {
    background-color: rgb(4, 57, 94);;
}

#feedTags .btn:hover {
    color: #000000 !important;
}
.avatar {
    border-radius:50%;
    width: 32px;
    height: 32px;
    position: relative;
    top: -10px;
}

.adjustBar {
    position: relative;
    top: -4px;
}

.chatMember {
    cursor: pointer;
}

.communityLogo {
    height:32px;
}

/* Below are additional styles for responsive design */
@media (max-width: 768px) {
    .search-bar {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-top: 0.25rem;
    }
}
