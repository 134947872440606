#userOverviewContainer {
    background-color: rgba(0, 0, 0, 0.1);
}

.userQuote {
    font-style: italic;
    font-size: 1.5em;
    padding: 0.5em 1em;
    border-radius: 5px;
    background-color: #4d5154;
    color: #FFFFFF;
    text-align: center;
}

.portrait {
    position: relative;
}

.portrait button {
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: calc(5%);
    left: 0;
    opacity: 0.8;
}

.portrait img {
    width: 100%;
    height: auto;
    border: 4px solid rgba(0, 0, 0, 0.3);
}

.portrait img:hover {
    border: 4px solid rgba(0, 0, 0, 0.7);
}

.portrait.rounded-circle {
    overflow: hidden;
}

.profileBar {
    border-radius: 20px 20px 5px 5px;
    margin-top: 1em;
    padding-bottom: 2em;
    background-color: #ffffff;
}

.profileBar h5 {
    text-align: center;
}

.bioBox {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
}

.referralBox {
    border-radius: 5px;
    background-color: #ffffff;
}

.achivementBox {
    border-radius: 5px 5px 20px 20px;
    background-color: #ffffff;
}

#photoCanvas {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.userName {
    text-align: center;
    cursor: pointer;
}

.profileBar .card-img-top {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.header-h5 {
    text-align: center;
    font-size: 1.25rem;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: var(--bs-heading-color);
    font-weight: 500;
    line-height: 1.2;
}
